/*=========================================================================================
    File Name: _themes.scss
    Description: partial- themes - imports theme styles
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import "themes/themeDark";
@import "themes/themeSemiDark";
