/*=========================================================================================
  File Name: main.scss
  Description: Main scss file. Imports other scss partials from 'vuexy' folder
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import "vuexy/variables";

@import "vuexy/layout";
@import "vuexy/typography";
@import "vuexy/misc";
@import "vuexy/extraComponents";
@import "vuexy/themes";
@import "vuexy/transitions";

@import "vuexy/customClasses";

// Vuesax fixes and enhancments
@import "vuexy/fixesVuesax";

// Tailwind Fixes
@import "vuexy/tailwindFix";

// rtl
@import "vuexy/rtl";
