/*=========================================================================================
  File Name: _rtl.scss
  Description: partial- rtl - imports rtl styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import "rtl/rtlFixes";
@import "rtl/rtlOverrides";
