/*=========================================================================================
  File Name: _extraComponents.scss
  Description: partial - imports extra components styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


@import "vuexy/extraComponents/awesomeSwiper";
@import "vuexy/extraComponents/formWizard";
@import "vuexy/extraComponents/vueSelect";
@import "vuexy/extraComponents/charts";
@import "vuexy/extraComponents/contextMenu";
@import "vuexy/extraComponents/quillEditor";
@import "vuexy/extraComponents/datePicker";
@import "vuexy/extraComponents/datetimePicker";
